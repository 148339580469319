/* ELEMENT STYLES -------------------- */

body {
  background: #e8e7e7;
  font-family: arial;
  text-transform: uppercase;
}

header {
  color: #fff;
  padding: 5px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #8063fa;
}

header h1 {
  flex-grow: 1;
  font-size: 1.5em;
  letter-spacing: 2px;
  font-weight: normal;
}

button,
input[type="submit"] {
  margin: 8px 5px;
  background-color: #44338d;
  border-radius: 5px;
  padding: 7px 8px;
  border: none;
  color: #fff;
  letter-spacing: 2px;
  font-weight: bold;
  text-shadow: none;
  text-transform: uppercase;
  outline: none;
  transition: background-color 0.2s ease, color 0.2s ease;
  touch-action: manipulation;
}

button:hover,
input[type="submit"]:hover {
  background: #fff;
  color: #44338d;
  cursor: pointer;
}

/* FORMS */

form {
  display: flex;
  background-color: #8063fa;
}

form input[type="text"] {
  flex-grow: 1;
  border-width: 0 0 1px 0;
  margin: 15px 10px 15px 15px;
  padding: 10px;
  border-radius: 5px;
  background-color: #5b45b9;
  border-style: none;
  text-shadow: none;
  text-transform: uppercase;
  color: #c7bcf8;
  letter-spacing: 2px;
  outline: none;
}

form input[type="text"]::-webkit-input-placeholder {
  color: #c7bcf8;
  letter-spacing: 2px;
}

form input[type="text"]:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

form input[type="submit"] {
  font-size: 0.6em;
  margin: 15px 15px 15px 0;
  padding: 10px;
  letter-spacing: 2px;
  font-weight: bold;
}

/* ICONS */

svg {
  width: 28px;
  height: 19px;
  margin-right: 5px;
  fill: #f0f0f0;
}

.is-high-score {
  fill: #ffc657;
  animation: grow 0.35s ease-in-out;
  transform-origin: 50% 50%;
}

@keyframes grow {
  50% {
    transform: scale(1.4) rotate(-3deg);
  }
}

/* COMPONENT STYLES -------------------- */

.scoreboard {
  background: #fff;
  border-radius: 6px;
}

.scoreboard-footer {
  display: flex;
  justify-content: center;
  background-color: #8063fa;
}

.scoreboard-footer button {
  padding: 12px;
}

.scoreboard-sets {
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 20px 0;
}

.scoreboard-sets-team {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.scoreboard-sets-team:last-of-type {
  border-left: solid 1px #eeeeee;
}

.scoreboard-sets-team span {
  line-height: 2em;
}

.scoreboard-sets-teamname {
  font-weight: bold;
}

.scoreboard-sets .set {
  display: flex;
  width: 15%;
  justify-content: center;
  align-content: center;
  margin: 2px auto;
}

.scoreboard-sets .set-won {
  background: #86ad34;
  color: #ffffff;
}

.teams {
  display: flex;
  flex-direction: row;
}

.team {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 1.2em;
  letter-spacing: 2px;
  max-width: 50%;
}

.team:last-of-type {
  border-left: solid 1px #eeeeee;
}

.team-name,
.counter-score {
  color: #2f2366;
}

.team-name {
  flex-grow: 1;
  padding: 24px 0;
  text-align: center;
  border-bottom: solid 1px #eeeeee;
  display: flex;
  justify-content: center;
}

.stats,
.stopwatch {
  width: 170px;
}

.stats {
  margin: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: normal;
}

.stats p {
  text-align: left;
  letter-spacing: 2px;
  font-size: 0.7em;
  margin: 0;
}

.stats p:last-child {
  font-weight: bold;
  background: #fff;
  color: #5b45b9;
  padding: 4px 6px;
  margin-top: 8px;
  border-radius: 8px;
  width: auto;
  display: none;
}

.stats p:last-child.switch-sides {
  display: flex;
}

.stopwatch {
  padding: 15px 10px 5px 10px;
  margin: -5px -10px -5px 10px;
  background: #5b45b9;
}

.stopwatch-time {
  display: block;
  font-family: monospace;
  font-size: 2.15em;
}

.stopwatch h2 {
  font-size: 0.6em;
  margin: 0;
  font-weight: normal;
  letter-spacing: 2px;
}

.counter {
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
}

.counter-action {
  border: none;
  font-size: 1.15em;
  font-weight: bold;
  color: #b0aeba;
  background: #dcdcdc;
  display: block;
  padding: 20px 20px;
  cursor: pointer;
  outline: none;
}

.counter-action:hover {
  color: #fff;
}

.increment:hover {
  background: #83d97c;
}

.decrement:disabled {
  opacity: 0.5;
}

.decrement:disabled:hover {
  background: #dcdcdc;
  color: #b0aeba;
}

.decrement:hover {
  background: #ef5350;
}

.counter-score {
  flex-grow: 1;
  text-align: center;
  font-family: monospace;
  font-size: 2em;
  line-height: 2em;
  letter-spacing: 0;
}

/* Media Queries */

@media (max-width: 650px) {
  header {
    flex-direction: column;
  }

  header h1 {
    order: -1;
  }

  .stopwatch {
    width: 100%;
    margin: -5px 0;
  }

  .stats {
    width: 100%;
    margin-bottom: 24px;
    align-items: center;
  }
}
